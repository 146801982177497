'use strict'

angular
  .module 'mundoReporting'
  .config ($stateProvider) ->
    $stateProvider
      .state 'reporting',
        url: '/reporting'
        templateUrl: 'mundo-reporting/views/mundo-reporting.tpl.html'
        controller: 'MundoReportingCtrl'
        controllerAs: 'reportingCtrl'
        data: {
          navigation: true
        }
        onEnter: [
          '$rootScope'
          'MundoNavigation'
          ($rootScope, MundoNavigation) ->
            $rootScope.reportingActive = true

            # # Set reporting active if it's not active yet
            # activeSection = MundoNavigation.getActiveSection()
            # if not (activeSection? and activeSection.id == 'reporting')
            #   MundoNavigation.setActiveSection MundoNavigation.sections.reporting
        ]
        onExit: [
          '$rootScope'
          'MundoNavigation'
          ($rootScope, MundoNavigation) ->
            delete $rootScope['reportingActive']
            MundoNavigation.sections.reporting.items.splice(1, (MundoNavigation.sections.reporting.items.length - 1))
        ]
      .state 'fullscreenReportMap',
        data:
          navigation: false
          anonymous: true
        url: '/reporting/shares/:sharesId/map/:configId',
        templateUrl: 'mundo-reporting/views/map/mundo-map-full.tpl.html',
        controller: 'ReportMapCtrl'
        controllerAs: 'mapCtrl'
